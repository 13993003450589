@font-face {
  font-family: "pp mori";
  src: url(fonts/mori/PPMori-Regular.otf) format("opentype"),
    url(fonts/mori/PPMori-Regular.ttf) format("truetype"),
    url(fonts/mori/PPMori-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "pp mori";
  src: url(fonts/mori/PPMori-SemiBold.otf) format("openType"),
    url(fonts/mori/PPMori-SemiBold.ttf) format("truetype"),
    url(fonts/mori/PPMori-SemiBold.woff) format("woff");
  font-weight: 600;
  font-style: normal;
}
